<template>
  <div class="section-editor">
    <base-select
      v-model="language"
      :items="$root.languages"
      placeholder="Language"
      :returnObject="false"
    ></base-select>
    <div
      class="section-editor-header d-flex align-center justify-space-between b-bottom"
    >
      <span class="d-block font-16 font-500"
        >{{ item ? "Edit " : "New " }} Trip Section</span
      >
    </div>
    <v-form
      ref="form"
      class="pt-4"
      v-model="valid"
      @submit.prevent="submitHandler"
    >
      <v-row class="section-editor-form">
        <!-- category name -->
        <v-col cols="12" md="6">
          <base-input label="Title" v-model="obj.title[language]"></base-input>
        </v-col>
        <!-- Tags -->
        <v-col cols="12" class="pb-0 mb-0">
          <v-autocomplete
            v-model="selectedTags"
            :items="displayedTags"
            outlined
            dense
            chips
            :filter="() => true"
            small-chips
            label="Search for tags"
            multiple
            clearable
            :search-input.sync="tagSearchQuery"
            @update:search-input="searchTags"
            @input="tagSearchQuery = undefined"
            :rules="[(v) => (!!v && v.length > 0) || '']"
          >
            <template v-slot:item="{ item, index }">
              <div class="d-flex mt-3">
                <p class="d-block" :key="index">
                  {{ item.title }}
                </p>
              </div>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip
                :key="index"
                close
                @click:close="obj.tags.splice(index, 1)"
                class="ma-2"
              >
                <div class="d-flex mt-3">
                  <p class="d-block">
                    {{ item.title }}
                  </p>
                </div>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" class="pb-0 mb-0">
          <p>Style</p>
          <v-radio-group
            v-model="obj.sectionStyle"
            class="d-flex ml-4"
            :rules="[(v) => !!v || '']"
          >
            <v-row class="d-block align-start">
              <v-radio class="mt-1" value="0">
                <template v-slot:label>
                  <div>
                    <img :src="horizontalLarge" />
                  </div>
                </template>
              </v-radio>
              <v-radio class="mt-1" value="1">
                <template v-slot:label>
                  <div>
                    <img :src="horizontalSmall" />
                  </div>
                </template>
              </v-radio>
              <v-radio class="mt-1" value="2">
                <template v-slot:label>
                  <div>
                    <img :src="verticalLarge" />
                  </div>
                </template>
              </v-radio>
              <v-radio class="mt-1" value="3">
                <template v-slot:label>
                  <div>
                    <img :src="verticalSmall" />
                  </div>
                </template>
              </v-radio>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- submit -->
      <v-col cols="12" class="py-0 ma-0">
        <!-- buttons -->
        <buttons-experience
          @cancel="$emit('close')"
          @next="submitHandler"
          text="Submit"
          :isValid="valid"
          :key="valid"
        ></buttons-experience>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  components: {
    ButtonsExperience
  },
  props: {
    item: {
      type: Object,
      default: undefined
    },
    sections: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    horizontalLarge: require("./img/horizontalLarge.png"),
    horizontalSmall: require("./img/horizontalSmall.png"),
    verticalLarge: require("./img/verticalLarge.png"),
    verticalSmall: require("./img/verticalSmall.png"),
    valid: false,
    obj: {
      title: {
        en: "",
        ar: ""
      },
      sectionStyle: "0",
      tags: [],
      country: "LB"
    },
    updateKey: false,
    tags: [],
    tagSearchQuery: undefined,
    loading: true,
    selectedTags: [],
    language: "en"
  }),
  computed: {
    displayedTags() {
      return [...(this.tags || []), ...(this.obj.tags || [])];
    }
  },
  methods: {
    async submitHandler() {
      if (this.obj.displayOrder == undefined) {
        this.obj.displayOrder = this.sections.length;
      }
      this.obj.tags = this.selectedTags.map((tag) => tag._id);
      this.$refs.form.validate();
      if (!this.valid) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth"
            });
        }, 100);
        return;
      }
      let res;
      if (this.item) {
        res = await this.$axios.put(`/section/${this.item._id}`, this.obj);
      } else {
        res = await this.$axios.post(`/section`, this.obj);
      }
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: `Section ${this.item ? "Edited" : "Created"} Successfully`,
          color: "success"
        });
        this.$emit("reloadData");
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    async searchTags(searchWord) {
      let { data } = await this.$axios.get(
        `tag?pageSize=&pageNumber=1&searchWord=${searchWord || ""}`
      );
      if (data) {
        this.tags = data.tags;
      }
    },
    async fetchItemID() {
      if (this.item) {
        this.loading = true;
        let { data } = await this.$axios.get(`section/${this.item._id}?`);
        if (data) {
          this.obj = data.section;
          this.obj.country = this.$root.countries[1].short
          this.selectedTags = this.obj.tags
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    }
  },
  created() {
    this.language = this.$root.language;
    this.obj.country = this.$root.countries[1].short
    this.fetchItemID();
  }
};
</script>

<style scoped>
.section-editor {
  .section-editor-header {
    height: 50px;
  }
  .section-editor-form {
    overflow: hidden;
  }
}
.arabic-text-field >>> input {
  text-align: right;
}
</style>
