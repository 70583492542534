<template>
  <div class="px-6">
    <div class="pt-5 pb-2 px-2">
      <div class="d-flex w-100 justify-space-between">
        <span>
          <p class="gray--text mr-4 mt-1">Library Sections ({{ total }})</p>
        </span>
        <base-input
          placeholder="Search Sections"
          @input="searchSections"
          :rules="[]"
          clearable
        ></base-input>
      </div>
      <div class="mt-4">
        <div
          class="py-2 text-center radius-5 secondary cursor-pointer"
          @click="createHandler"
        >
          <span class="white--text font-16">Add New Section</span>
        </div>
      </div>
    </div>
    <div class="sections-table">
      <transition-group>
        <v-data-table
          key="v-data-table"
          v-if="!loading"
          :server-items-length="total"
          :headers="headers"
          :items="listItems"
          :items-per-page="-1"
          hide-default-footer
          item-key="_id"
          class="elevation-0"
          @click:row="itemClicked"
          mobile-breakpoint="0"
          :item-class="
            () => {
              return 'cursor-pointer';
            }
          "
        >
          <template v-slot:item.title="{ item }">
            <span class="d-block font-16 font-500 white-grey--text">{{
              item.title[$root.language]
            }}</span>
          </template>

          <template v-slot:item.order="{ item, index }">
            <div class="d-flex">
              <v-row>
                <v-col cols="1.5" sm="0">
                  <div style="width: 40px; height: 40px">
                    <v-icon
                      size="40"
                      class="cursor-pointer ma-0 pa-0"
                      @click="pushSectionDown(item, $event)"
                      color="black"
                      alt=""
                      :disabled="index >= listItems.length - 1"
                      >mdi-arrow-down-bold-circle</v-icon
                    >
                  </div>
                </v-col>
                <v-col cols="1.5" sm="0">
                  <div style="width: 40px; height: 40px">
                    <v-icon
                      size="40"
                      class="cursor-pointer ma-0 pa-0"
                      @click="pushSectionUp(item, $event)"
                      color="black"
                      :disabled="index == 0"
                      >mdi-arrow-up-bold-circle</v-icon
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>

          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <div class="d-flex mx-auto">
              <div
                class="d-block icon-cover mr-1 cursor-pointer base-btn"
                @click="deleteConfirm(item, $event)"
              >
                <v-icon class="mx-2" color="red">mdi-delete</v-icon>
              </div>
            </div>
          </template>
        </v-data-table>

        <div v-else key="empty-state" style="height: 100vh" class="pt-5">
          <v-skeleton-loader type="card"></v-skeleton-loader>
          <v-col
            cols="12"
            md="12"
            v-for="(item, i) in [1, 2, 3, 4, 5, 6]"
            :key="i"
          >
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </v-col>
        </div>
      </transition-group>
    </div>

    <!-- add section -->
    <v-dialog
      v-model="addSectionDialog"
      content-class="white px-6 py-7"
      max-width="600"
    >
      <add-trip-section
        @close="addSectionDialog = false"
        @reloadData="reloadData"
        :key="addSectionDialog"
        :sections="listItems"
      ></add-trip-section>
    </v-dialog>

    <v-dialog
      v-model="editSectionDialog"
      content-class="white px-6 py-7"
      max-width="600"
    >
      <add-trip-section
        @close="editSectionDialog = false"
        @reloadData="reloadData"
        :key="editSectionDialog"
        :item="selectedItem"
        :sections="listItems"
      ></add-trip-section>
    </v-dialog>

    <!-- delete category -->
    <v-dialog v-model="deleteDialog" max-width="600px">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
        hide-overlay
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import AddTripSection from "@/components/dialogs/Library/Section/TripSection.vue";
export default {
  components: {
    AddTripSection
  },
  data: () => ({
    avatar:
      "https://www.ekoparkotomasyon.com/wp-content/public_html/cart/image/data/uploads/2013/12/default-placeholder.png",
    headers: [
      { text: "Order", value: "order", width: 100, sortable: false },
      { text: "Title", value: "title", sortable: false },
      { text: "", value: "actions", width: "110px", sortable: false }
    ],
    listItems: [],
    total: 0,
    editSectionDialog: false,
    addSectionDialog: false,
    deleteDialog: false,
    selectedItem: undefined,
    searchWord: "",
    loading: true
  }),
  methods: {
    createHandler() {
      this.addSectionDialog = true;
    },
    deleteConfirm(item, event) {
      this.deleteDialog = true;
      this.selectedItem = item;
      event.stopPropagation();
    },
    itemClicked(item) {
      this.selectedItem = item;
      this.editSectionDialog = true;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/section/${this.selectedItem._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        await this.fetchList();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    reloadData() {
      this.deleteDialog = false;
      this.editSectionDialog = false;
      this.addSectionDialog = false;
      this.fetchList();
    },
    async searchSections(searchWord) {
      this.listItems = [];
      this.searchWord = searchWord;
      await this.fetchList();
    },
    async pushSectionUp(item, event) {
      event.stopPropagation();
      let index = this.listItems.findIndex(
        (element) => element._id == item._id
      );
      if (index > 0) {
        let otherItem = this.listItems[index - 1];
        let displayOrder = item.displayOrder;
        this.editSectionOrder(item, otherItem.displayOrder);
        this.editSectionOrder(otherItem, displayOrder);
      }
    },
    async pushSectionDown(item, event) {
      event.stopPropagation();
      let index = this.listItems.findIndex(
        (element) => element._id == item._id
      );
      if (index < this.listItems.length - 1) {
        let otherItem = this.listItems[index + 1];
        let displayOrder = item.displayOrder;
        this.editSectionOrder(item, otherItem.displayOrder);
        this.editSectionOrder(otherItem, displayOrder);
      }
    },
    async editSectionOrder(item, displayOrder) {
      await this.$axios.put(`/section/${item._id}`, {
        displayOrder: displayOrder
      });
      this.reloadData();
    },
    async fetchList() {
      this.loading = true;
      let { data } = await this.$axios.get(
        `section?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }&pageSize=&pageNumber=1`
      );
      if (data) {
        this.listItems = data.sections;
        this.listItems.sort(
          (section1, section2) => section1.displayOrder - section2.displayOrder
        );
        this.total = data.count;
      }
      this.loading = false;
    }
  },
  async created() {
    await this.fetchList();
  }
};
</script>

<style lang="scss" scoped>
.header {
  overflow: hidden;
  height: 80px;
}
.sections-table {
  overflow-y: auto;
  height: calc(100vh - 80px - 100px);

  .mdi-arrow-up {
    display: none;
  }
}
</style>
